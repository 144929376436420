// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/www/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/www/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/www/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-surveys-2019-thank-you-js": () => import("/opt/build/repo/www/src/pages/surveys/2019-thank-you.js" /* webpackChunkName: "component---src-pages-surveys-2019-thank-you-js" */),
  "component---src-pages-surveys-2019-js": () => import("/opt/build/repo/www/src/pages/surveys/2019.js" /* webpackChunkName: "component---src-pages-surveys-2019-js" */)
}

